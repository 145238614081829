import React, { useEffect, useState } from "react";
import { preloadImage } from "./lib/assetsLoader";
import { ContentfulProvider } from "react-contentful";
import { contentfulClient } from "./lib/contentfulConfig";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { setGlobal } from "reactn";
import TopicDetailView from "./views/TopicDetailView/TopicDetailView";
import LoadingView from "./views/LoadingView/LoadingView";
import MainView from "./views/MainView/MainView";

import { allAssets } from "./data/contentData";
import { CookieBanner } from "@palmabit/react-cookie-law";
// import { useMatomo } from '@datapunt/matomo-tracker-react'

import utils from "./utils";

import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import "./App.scss";
import "./assets/fonts/Allzweck.otf";
import "./styles.css";
import ImpressumView from "./views/ImpressumView/ImpressumView";

setGlobal({
  activeViewIndex: 0,
  showNavigation: false,
  language: "DE",
  showingTheme: window.location.href.includes("detail") ? true : false,
  showingCollage: "false",
  firstLoad: true,
  fullScreenItem: "",
});

let vh = window.innerHeight * 0.01;
let vwidth = window.innerWidth;
document.documentElement.style.setProperty("--vh", `${vh}px`);
document.documentElement.style.setProperty("--vwidth", `${vwidth}px`);

const App = () => {
  const [loading, setLoading] = useState(true);
  const [showApp, setShowApp] = useState(false);
  const [minTimePassed, setMinimumTimePassed] = useState(false);
  const [filesLoaded, setFilesLoaded] = useState([]);

  useEffect(() => {
    _loadAllAssets(allAssets);
  }, []);

  const _loadAllAssets = async (data) => {
    var loadedArray = [""];
    await data.map(async (file) => {
      const fileLoad = await preloadImage(file);
      let _filesLoaded = loadedArray.push(fileLoad);
      setFilesLoaded(_filesLoaded);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setMinimumTimePassed(true);
    }, 600);
  }, []);

  useEffect(() => {
    if (!loading && minTimePassed) setShowApp(true);
  }, [loading, minTimePassed]);

  // const { trackPageView } = useMatomo()
  // useEffect(() => {
  //   trackPageView()
  // }, [trackPageView])

  const AnimatedSwitch = withRouter(({ location }) => {
    const currentScreen = location.pathname;
    const animationClassNames = currentScreen.includes("detail")
      ? "slide-forward"
      : "slide-backward";
    return (
      <TransitionGroup
        childFactory={(child) =>
          React.cloneElement(child, {
            classNames: animationClassNames,
          })
        }
      >
        <CSSTransition
          key={location.key}
          classNames={animationClassNames}
          timeout={1000}
        >
          <Switch location={location}>
            <Route exact path="/">
              <MainView />
            </Route>
            <Route path="/detail/:topicId">
              <TopicDetailView />
            </Route>
            <Route path="/impressum">
              <ImpressumView />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    );
  });

  const getAssetsLoad = () => {
    return (100 * filesLoaded) / allAssets.length;
  };

  const LoaderAnimation = () => {
    return (
      <LoadingView
        loaded={getAssetsLoad()}
        skipToView={() => setLoading(false)}
      />
    );
  };

  return (
    <div>
      <ContentfulProvider client={contentfulClient}>
        {window.location.href.includes("detail") ||
        window.location.href.includes("impressum") ||
        window.location.href.includes("privacy") ? (
          <BrowserRouter>
            <AnimatedSwitch />
          </BrowserRouter>
        ) : showApp ? (
          <BrowserRouter>
            <AnimatedSwitch />
          </BrowserRouter>
        ) : (
          <LoaderAnimation />
        )}
      </ContentfulProvider>
      <div>
        {
          // https://github.com/Palmabit-IT/react-cookie-law
        }
        <CookieBanner
          wholeDomain={false}
          message={`Diese Seite verwendet Sitzungscookies, um Ihnen die Navigation zu erleichtern, sowie allgemeine analytische Cookies.`}
          privacyPolicyLinkText="Impressum und Datenschutzerklärung"
          managePreferencesButtonText="Cookies verwalten"
          acceptButtonText="Alles akzeptieren"
          policyLink="/impressum"
          onAccept={() => {}}
          // todo, add or revoke mfire
          // onDeclineStatistics={() => {
          //   utils.OptOutMatomo();
          // }}
          // onAcceptStatistics={() => {
          //   utils.RemoveOptOutMatomo();
          // }}
          showDeclineButton={false}
          showPreferencesOption={false}
          showStatisticsOption={true}
          showMarketingOption={false}
          preferencesDefaultChecked={false}
          statisticsDefaultChecked={true}
          marketingDefaultChecked={false}
          styles={{
            button: { fontFamily: "DenmarkBold" },
          }}
        />
      </div>
    </div>
  );
};

export default App;
