import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';

import './DetailTitle.scss';

const DetailTitle = (props) => {
    const [isRefurbishment, setIsRefurbishment] = useState(false);
    
    useEffect(() => {
       !!props.title &&  props.title.includes('-refurbishment') && setIsRefurbishment(true)
    }, [props])
    return (
        <div className={`DetailTitle ${isRefurbishment && 'refurbishment'}`}>
                {/* {isRefurbishment && <div className='refurbishment'>Refurbishment</div>} */}
                {/* <Fade bottom> */}
                    <div className='detail-title-content'>
                        <span className='number'>{props.id + 1}.</span><br />
                        <span className='text'>
                        {!!props.title && props.title.replace('-refurbishment', '')}</span>
                    </div>
                {/* </Fade> */}
            </div>
        
    )
}

export default DetailTitle;