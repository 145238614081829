import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import ExtraBlock from '../ExtraBlock/ExtraBlock';
import { ReactComponent as OpenQuote } from '../../assets/open-quote.svg'
import { ReactComponent as CloseQuote } from '../../assets/close-quote.svg'


import './SectionView4.scss';

const SectionView4 = (props) => {
    const [formattedText, setFormattedText] = useState([])

    useEffect(() => {
        const paragraphArr = props.text && props.text.map(item => {
            const paragraphContent = item.content.map(_item => {
                const contentObj = {
                    text: item.nodeType === 'blockquote' ?
                        !!_item.content && !!_item.content[0] && _item.content[0].value
                        : item.nodeType === "hyperlink" ? 
                        !!_item.content && !!_item.content[0] && _item.content[0].value
                        : _item.value,
                    marks: !!_item.marks && !!_item.marks[0] && _item.marks[0].type,
                    type: item.nodeType,
                    url: !!_item.data && !!_item.data.uri && _item.data.uri
                }
                return contentObj
            })
            return paragraphContent;
        })
        setFormattedText(paragraphArr)
    }, [props.text])

    const renderText = () => {
        
        return (
            <div className={`text ${!!props.text && props.text.length > 2 ? 'column' : 'row'}`}>
                {formattedText && formattedText.length > 0 && formattedText.map(item => {
                    
                    return (
                        <div className='text-paragraph'>
                            {item.map((textSection, id) => {
                                return textSection.type === 'blockquote' ?
                                    <div className='quote' key={id}>
                                        <div className='open-quote'>
                                            {/* <OpenQuote/> */}
                                        </div>
                                        <span key={textSection.text}
                                            className={`${textSection.marks === 'bold' && 'bold'} 
                                            ${textSection.marks === 'italic' && 'italic'}  
                                            ${textSection.type === 'heading-6' ? 'highlight1' : ''}
                                            ${textSection.type === 'heading-5' ? 'highlight2' : ''}
                                            ${textSection.type === "blockquote" ? 'quote' : ''}`
                                            }
                                        >
                                            <span className='open'><OpenQuote /></span>
                                            {textSection.text}
                                            <span className='close'>
                                                <span className='close-icon'>
                                                <CloseQuote /></span></span></span>
                                        <div className='close-quote'>
                                            {/* <CloseQuote/> */}
                                        </div>
                                    </div>
                                    : !!textSection.url ?
                                        <a key={id} className='hyper-link' href={textSection.url}>Spotify</a>
                                    : <span key={textSection.text}
                                        className={`${textSection.marks === 'bold' && 'bold'} 
                                    ${textSection.marks === 'italic' && 'italic'}  
                                    ${textSection.type === 'heading-6' ? 'highlight1' : ''}
                                    ${textSection.type === 'heading-5' ? 'highlight2' : ''}
                                    ${textSection.type === "blockquote" ? 'quote' : ''}`
                                        }
                                    >
                                        {textSection.text}
                                        {textSection.type === 'heading-5' && <span><br /><br /><br /></span>}
                                    </span>

                            })}
                        </div>
                    )

                })}

            </div>
        )
    }

    const getIsDark = () => {
        return props.isOdd
    }

    return (
        <div className={`SectionView4 ${getIsDark() ? 'light' : 'dark'} detail`} >
            {!!props.extraText && <ExtraBlock extraText={props.extraText} isOdd={props.isOdd}  extraImg={props.extraImg}/>}
            <div className='detail-content4'>
                {/* <Fade bottom> */}
                    {renderText()}
                {/* </Fade> */}
            </div>
        </div>
    )
}

export default SectionView4;